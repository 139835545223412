import React from "react";
import Auth0ProviderWithRedirectCallbackParent from "../routingAndAuth/auth0ProviderWithRedirectCallbackParent";
import { googleCalendarProviderConfig } from "../routingAndAuth/auth0ProviderConfigs";
import googleCalendarImage from "../../images/google-calendar.png";
import AddGoogleScopeButton from "./addGoogleScopeButton";

const ConnectGoogleCalendar = ({ connectedText, loadingText }) => {
  return (
    <Auth0ProviderWithRedirectCallbackParent {...googleCalendarProviderConfig}>
      <AddGoogleScopeButton
        buttonTitle={"Connect Google Calendar"}
        iconSrc={googleCalendarImage}
        iconAlt={"Connect Google Calendar"}
        scopesToAdd={["https://www.googleapis.com/auth/calendar"]}
        connectedText={connectedText}
        loadingText={loadingText}
      />
    </Auth0ProviderWithRedirectCallbackParent>
  );
};

export default ConnectGoogleCalendar;
