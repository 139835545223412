import useQueryGenericPost from "../../../hooks/framework/useQueryGenericPost";
import { O_SERVICE_AUTH_UPDATE_GOOGLE_AUTH_TOKEN_INFO } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";

const useUpdateGoogleAuthTokenInfo = (onSubmit, onSuccess, onError) => {
  return useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_AUTH_UPDATE_GOOGLE_AUTH_TOKEN_INFO,
    undefined,
    onSubmit,
    onSuccess,
    onError,
    true,
  );
};

export default useUpdateGoogleAuthTokenInfo;
