import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUpdateGoogleAuthTokenInfo from "./hooks/useUpdateGoogleAuthTokenInfo";
import logo from "../../images/tldrparents-logo-horizontal-white.svg";
import { Card } from "antd";

const GoogleAddScopesCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: updateGoogleAuthTokenInfo } = useUpdateGoogleAuthTokenInfo(
    null,
    () => {
      let next = searchParams.get("next");
      if (!next) {
        next = "/";
      }
      navigate(next);
    },
    null,
  );

  useEffect(() => {
    if (updateGoogleAuthTokenInfo) {
      updateGoogleAuthTokenInfo();
    }
  }, [updateGoogleAuthTokenInfo]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <img src={logo} alt="TLDR Parents" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: 7,
        }}
      >
        <div style={{ width: "100%", maxWidth: 500 }}>
          <Card>
            <h2>Updating Google Connection...</h2>
          </Card>
        </div>
      </div>
    </>
  );
};

export default GoogleAddScopesCallback;
