import React, { useEffect } from "react";
import HelpIconAndModal from "../helpIconAndModal";
import TodoListEducationModal from "../familyOrganizer/todoList/todoListEducationModal";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";

const EmbeddedGoogleCalendar = () => {
  const { currentFamily, setBreadcrumbs, setBreadcrumbExtra, browserTimezone } =
    useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Google Calendar",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={TodoListEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const getJsxForCalendar = () => {
    if (!currentFamily) {
      return <AbsoluteSpin />;
    } else {
      if (!currentFamily.google_calendar_id) {
        return <>No google calendar</>;
      } else {
        let displayTimezone = "UTC";
        if (browserTimezone) {
          displayTimezone = browserTimezone;
        }
        return (
          <>
            <iframe
              title="Connected Google Calendar"
              src={`https://calendar.google.com/calendar/embed?src=${currentFamily.google_calendar_id}&ctz=${displayTimezone}`}
              style={{
                border: 0,
                width: "100%",
                height: "100vh",
                minHeight: 500,
                maxHeight: 1000,
              }}
              frameBorder="0"
              scrolling="no"
            ></iframe>
            <>{currentFamily.google_calendar_id}</>
          </>
        );
      }
    }
  };

  return <>{getJsxForCalendar()}</>;
};

export default EmbeddedGoogleCalendar;
