import React from "react";
import FamilyMemberName from "../../../familyMembers/familyMemberName";

const TodoCompletedByPart = ({ todoItem }) => {
  const getCompletedBy = () => {
    return (
      <>
        Completed by <FamilyMemberName userId={todoItem.completed_by_id} />
      </>
    );
  };

  return getCompletedBy();
};

export default TodoCompletedByPart;
