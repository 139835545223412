import React, { useEffect } from "react";
import useListMagicEvents from "../magicCalendar/hooks/useListMagicEvents";
import useListQueryRollup from "../artifactRollups/hooks/useListQueryRollup";
import { Card, Col, Row, Space, Typography } from "antd";
import useListMessages from "../../hooks/emailCollector/messages/useListMessages";
import { useNavigate } from "react-router-dom";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import HandymanIcon from "@mui/icons-material/Handyman";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import useListOrchestrateObjects from "../../hooks/orchestrate/useListOrchestrateObjects";
import {
  O_SERVICE_BOARDS_LIST,
  O_SERVICE_CLIPPINGS_LIST,
  O_SERVICE_TODOS_LIST,
} from "../../serviceUrls/serviceUrlsOrchestrate";

const HomeDashboard = () => {
  useEffect(() => {
    document.title = "Dashboard | TLDR Parents";
  }, []);

  const { familyId } = useTldrPageContext();
  const navigate = useNavigate();

  const { data: suggestedEventsSearchQueryResults } = useListMagicEvents(
    false,
    false,
    1,
    0,
  );

  const { data: listQueryData } = useListQueryRollup(
    "tldr",
    null,
    1,
    0,
    "message_date",
    false,
  );

  const { data: messages } = useListMessages(false, 1, 0, "message_date");

  const { data: clippings } = useListOrchestrateObjects(
    O_SERVICE_CLIPPINGS_LIST,
    true,
  );

  const { data: todos } = useListOrchestrateObjects(
    O_SERVICE_TODOS_LIST,
    true,
    {
      completed: false,
    },
  );

  const { data: boards } = useListOrchestrateObjects(
    O_SERVICE_BOARDS_LIST,
    true,
  );

  const getStatisticCard = (number, icon, text, pathPart) => {
    return (
      <Card
        style={{ borderColor: "#168dff", cursor: "pointer" }}
        styles={{ body: { padding: 15 } }}
        onClick={() => navigate(`/families/${familyId}/${pathPart}`)}
      >
        <Typography.Text
          type={"secondary"}
          style={{
            textTransform: "uppercase",
            fontWeight: 500,
          }}
        >
          <Space>
            {icon && <>{icon}</>}
            {text}
          </Space>
        </Typography.Text>

        <div
          style={{
            fontSize: 40,
            fontWeight: "bold",
            color: "#ff4081",
            width: "100%",
            textAlign: "center",
            padding: 20,
          }}
        >
          {number || "-"}
        </div>
      </Card>
    );
  };

  const getForReviewJsx = () => {
    return (
      <>
        <h2>Explore & Review</h2>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            {getStatisticCard(
              suggestedEventsSearchQueryResults?.found,
              <AutoAwesomeOutlinedIcon sx={{ fontSize: 18 }} />,
              "Event Suggestions",
              "event-suggestions",
            )}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            {getStatisticCard(
              listQueryData?.found,
              <LinkOutlinedIcon sx={{ fontSize: 18 }} />,
              "Link Suggestions",
              "link-suggestions",
            )}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            {getStatisticCard(
              messages?.found,
              <EmailOutlinedIcon sx={{ fontSize: 18 }} />,
              "Inbox Messages",
              "smart-inbox",
            )}
          </Col>
        </Row>
      </>
    );
  };

  const getOrganizerJsx = () => {
    return (
      <>
        <h2>Get Stuff Done</h2>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            {getStatisticCard(
              clippings ? clippings.length : "-",
              <HandymanIcon sx={{ fontSize: 18 }} />,
              "Items on Workbench",
              "workbench",
            )}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            {getStatisticCard(
              todos ? todos.length : "-",
              <FormatListBulletedOutlinedIcon sx={{ fontSize: 18 }} />,
              "Open To-dos",
              "todos",
            )}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            {getStatisticCard(
              boards ? boards.length : "-",
              <DashboardOutlinedIcon sx={{ fontSize: 18 }} />,
              "Family Boards",
              "boards",
            )}
          </Col>
        </Row>
      </>
    );
  };

  const getJsx = () => {
    return (
      <>
        {getForReviewJsx()}
        <div style={{ height: 30 }} />
        {getOrganizerJsx()}
      </>
    );
  };

  return getJsx();
};

export default HomeDashboard;
