import React, { useEffect } from "react";
import { Form, Input } from "antd";

const CreateOrEditClippingForm = ({
  initialValues,
  ecObject,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name={"CreateOrEditClippingForm"}
        initialValues={initialValues}
        onValuesChange={(value) => {
          if (onValuesChange) {
            onValuesChange?.(value, form.getFieldsValue());
          }
        }}
      >
        <Form.Item name="notes" label="Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateOrEditClippingForm;
