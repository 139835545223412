import React from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import useIsDebugMode from "../../../hooks/utils/useIsDebugMode";

const SecondaryMessageMenu = ({
  familyId,
  uniqueMessageId,
  selectedTabValue,
}) => {
  const navigate = useNavigate();
  const { isDebugMode } = useIsDebugMode();

  const tabItems = [
    {
      label: "TLDR",
      key: "tldr",
    },
    {
      label: "Full-text",
      key: "full-text",
    },
    {
      label: "More...",
      key: "more",
    },
  ];
  if (isDebugMode) {
    tabItems.push({
      label: "Debug",
      key: "debug",
    });
  }

  return (
    <Tabs
      activeKey={selectedTabValue || "tldr"}
      items={tabItems}
      onChange={(value) =>
        navigate(
          `/families/${familyId}/smart-inbox/${uniqueMessageId}/${value}`,
        )
      }
    />
  );
};

export default SecondaryMessageMenu;
