import React from "react";
import { Button } from "antd";

import useManageGoogleConnections from ".//hooks/useManageGoogleConnections";

const DisconnectAllGoogleButton = () => {
  const { googleAuthTokenInfoState, revokeGoogleRefreshToken } =
    useManageGoogleConnections();

  const getButtonIfNeeded = () => {
    let scopesWithoutBasic = [];
    if (googleAuthTokenInfoState?.scope) {
      const basicScopes = [
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
        "openid",
      ];
      scopesWithoutBasic = googleAuthTokenInfoState.scope.filter(
        (item) => !basicScopes.includes(item),
      );
    }

    return (
      <>
        {scopesWithoutBasic.length > 0 ? (
          <Button
            type={"primary"}
            ghost={true}
            onClick={() => {
              revokeGoogleRefreshToken({});
            }}
          >
            Disconnect All
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };

  return getButtonIfNeeded();
};

export default DisconnectAllGoogleButton;
