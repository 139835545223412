import React from "react";
import { Button, Col, List, Row, Space, Typography } from "antd";
import FamilyMemberAvatarIcon from "../../familyMembers/familyMemberAvatarIcon";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import FamilyMemberName from "../../familyMembers/familyMemberName";
import FormattedDateTimeRange from "../../common/generic/formattedDateTimeRange";
import {
  getDayJsDatetimeFromStringsGivenTimezones,
  getFormattedUtcDateTimeInUsersTz,
} from "../../../utils/dayJsUtils";
import { getOpenGoogleCalendarToDateUrl } from "../../../utils/utils";

const { Text } = Typography;

const GoogleEventListItem = ({
  googleEvent,
  googleEventData,
  onClickViewDetails,
  onClickDelete,
}) => {
  const { familyId, currentFamily } = useTldrPageContext();

  const startDate = getDayJsDatetimeFromStringsGivenTimezones(
    googleEvent?.start_date,
    googleEvent?.start_time,
    "UTC",
    currentFamily?.timezone || "UTC",
  );
  const urlToGoogleCalendar = getOpenGoogleCalendarToDateUrl(
    startDate.format("YYYY-MM-DD"),
  );

  return (
    <List.Item>
      <Row wrap={false} style={{ width: "100%" }}>
        <Col flex={"none"} style={{ paddingRight: 5 }}>
          <FamilyMemberAvatarIcon
            familyId={familyId}
            userId={googleEvent?.creator_id}
          />
        </Col>
        <Col flex={"auto"}>
          <Space
            direction={"vertical"}
            style={{ cursor: "pointer", width: "100%" }}
            onClick={() => onClickViewDetails(googleEvent, googleEventData)}
          >
            <Text strong ellipsis={true}>
              {googleEvent.name}
            </Text>
            {googleEvent.details && (
              <Text type="secondary" ellipsis={true}>
                {googleEvent.details}
              </Text>
            )}
            {googleEvent.location && (
              <Text type="secondary" ellipsis={true}>
                {googleEvent.location}
              </Text>
            )}
            <Text type={"secondary"}>
              <FormattedDateTimeRange
                startDateAsString={googleEvent?.start_date}
                startTimeAsString={googleEvent?.start_time}
                endDateAsString={googleEvent?.end_date}
                endTimeAsString={googleEvent?.end_time}
                timezoneInterpret={"UTC"}
                timezoneDisplay={currentFamily?.timezone || "UTC"}
              />
            </Text>
            <Space>
              <Text type="secondary" ellipsis={true}>
                Added to Google Calendar by{" "}
                <FamilyMemberName userId={googleEvent?.creator_id} />
                {" on "}
                {getFormattedUtcDateTimeInUsersTz(
                  googleEvent.created,
                  currentFamily?.timezone,
                )}
              </Text>
            </Space>
          </Space>
          <div style={{ marginTop: 8, marginBottom: 5 }}>
            <Space>
              <Button
                type={"primary"}
                onClick={() => {
                  window.open(urlToGoogleCalendar, "_blank");
                }}
              >
                Open Google Calendar
              </Button>
              <Button
                type={"primary"}
                ghost={true}
                danger={true}
                onClick={() => onClickDelete(googleEvent)}
              >
                Delete from TLDR Parents
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </List.Item>
  );
};

export default GoogleEventListItem;
