import React from "react";
import Auth0ProviderWithRedirectCallbackParent from "../routingAndAuth/auth0ProviderWithRedirectCallbackParent";
import { gmailProviderConfig } from "../routingAndAuth/auth0ProviderConfigs";
import gmailConnectImage from "../../images/gmail.png";
import AddGoogleScopeButton from "./addGoogleScopeButton";

const ConnectGmail = () => {
  return (
    <Auth0ProviderWithRedirectCallbackParent {...gmailProviderConfig}>
      <AddGoogleScopeButton
        buttonTitle={"Connect Gmail"}
        iconSrc={gmailConnectImage}
        iconAlt={"Connect Gmail"}
        scopesToAdd={["https://www.googleapis.com/auth/gmail.readonly"]}
        connectedText={"Gmail Connected"}
        loadingText={"Loading..."}
      />
    </Auth0ProviderWithRedirectCallbackParent>
  );
};

export default ConnectGmail;
