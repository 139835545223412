import React from "react";
import {
  Badge,
  Button,
  Col,
  Divider,
  List,
  Row,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getShortHumanizedDateTimeForEmail } from "../../../utils/dayJsUtils";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import useIsDebugMode from "../../../hooks/utils/useIsDebugMode";
import SmallButtonWithIconGroup from "../../common/generic/smallButtonWithIconGroup";
import SmallButtonWithIcon from "../../common/generic/smallButtonWithIcon";
import { SyncOutlined } from "@ant-design/icons";
import useMessagesAsCards from "./hooks/useMessagesAsCards";
import SortSelect from "../../common/commonTldr/sortSelect";
import useMapEcQueryIdToOwnerId from "./hooks/useMapEcQueryIdToOwnerId";
import FamilyMemberAvatarIcon from "../../familyMembers/familyMemberAvatarIcon";
import FamilyMemberName from "../../familyMembers/familyMemberName";
import SpaceBetweenRow from "../../common/generic/spaceBetweenRow";
import StandardPagination from "../../common/generic/standardPagination";
import SmartInboxEducationModal from "./smartInboxEducationModal";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const { Text } = Typography;

const MessagesAsCards = ({ viewArchived }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { familyId, currentFamily } = useTldrPageContext();

  const {
    messages,
    currentPage,
    pageSize,
    orderBy,
    changePage,
    changeOrderOrFilterAndNavigate,
    runGmailQueryHook,
  } = useMessagesAsCards(viewArchived);

  const { isDebugMode } = useIsDebugMode();
  const { queryIdsToOwnerIds } = useMapEcQueryIdToOwnerId();

  const getOwnerIdForMessage = (message) => {
    if (!message?.unique_tenancy_message_id) {
      return null;
    }
    const messageQueryId = message.unique_tenancy_message_id.split("-")[0];
    return queryIdsToOwnerIds[messageQueryId] || null;
  };

  const getDebugButtonGroup = (message) => {
    const buttonProperties = [
      {
        iconType: "BUG",
        onClick: (e) => {
          if (e.stopPropagation) {
            e.stopPropagation(); // W3C model
          } else {
            e.cancelBubble = true; // IE model
          }
          const searchParamsLocal = createSearchParams(searchParams);
          searchParamsLocal.delete("page");
          navigate({
            pathname: `/families/${familyId}/${viewArchived ? "archived-messages" : "smart-inbox"}/${currentFamily.tenant_id}-${message.unique_tenancy_message_id}/debug`,
            search: searchParamsLocal.toString(),
          });
        },
      },
    ];
    return (
      <SmallButtonWithIconGroup
        buttons={buttonProperties}
        showVertically={false}
      />
    );
  };

  const getDebugRow = (message) => {
    return (
      isDebugMode && (
        <Space style={{ paddingTop: 5 }}>
          <Tooltip title={message.status}>
            {message.status === "complete" ? (
              <Space>
                <Badge status="success" />
              </Space>
            ) : (
              <Space>
                <Badge status="processing" />
              </Space>
            )}
          </Tooltip>
          {getDebugButtonGroup(message)}
        </Space>
      )
    );
  };

  // const getCheckBoxPart = (message) => {
  //   return (
  //     <div style={{ paddingRight: 8, paddingLeft: 8 }}>
  //       <Checkbox
  //         style={{ transform: "scale(1.25)" }}
  //         checked={isMessageChecked(message)}
  //         onChange={(value) => {
  //           const uniqueMessageId = `${message.tenant_id}-${message.unique_tenancy_message_id}`;
  //           const isChecked = value.target.checked;
  //           if (isChecked) {
  //             setCheckedMessageIds([...checkedMessageIds, uniqueMessageId]);
  //           } else {
  //             setCheckedMessageIds(
  //               checkedMessageIds.filter((item) => {
  //                 return item !== uniqueMessageId;
  //               }),
  //             );
  //           }
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const getBodyPart = (message) => {
    const userId = getOwnerIdForMessage(message);
    const messageSubject =
      message.subject && message.subject !== ""
        ? message.subject
        : "[no subject]";
    return (
      <div
        style={{
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => {
          const searchParamsLocal = createSearchParams(searchParams);
          searchParamsLocal.delete("page");
          navigate({
            pathname: `/families/${familyId}/${viewArchived ? "archived-messages" : "smart-inbox"}/${currentFamily.tenant_id}-${message.unique_tenancy_message_id}`,
            search: searchParamsLocal.toString(),
          });
        }}
      >
        <div>
          <Text strong={true} ellipsis={true}>
            {message.from_address[1] || message.from_address[0]}
          </Text>
        </div>
        <div>
          <Text ellipsis={true}>{messageSubject}</Text>
        </div>
        <div style={{ paddingTop: 5 }}>
          <Space>
            <FamilyMemberAvatarIcon userId={userId} />
            <Text type={"secondary"}>
              <FamilyMemberName userId={userId} />
            </Text>
            <Divider type={"vertical"} style={{ margin: 0 }} />
            <Text type={"secondary"}>
              {getShortHumanizedDateTimeForEmail(message.created_ts)}
            </Text>
          </Space>
        </div>
        <div>{getDebugRow(message)}</div>
      </div>
    );
  };

  const getRightPart = (message) => {
    const hasAttachment = message.attachments && message.attachments.length > 0;
    return (
      <div style={{ paddingRight: 8 }}>
        <div>
          <Text type="secondary" style={{ textAlign: "right" }}>
            {getShortHumanizedDateTimeForEmail(message.timestamp)}
          </Text>
        </div>
        {hasAttachment && (
          <div style={{ marginTop: 3, textAlign: "right" }}>
            <AttachFileOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
          </div>
        )}
        <div style={{ textAlign: "right" }}>
          {message.status !== "complete" && (
            <Tooltip title="processing">
              <Tag
                style={{ marginRight: 0 }}
                icon={<SyncOutlined spin />}
                color="processing"
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const getMessageListItem = (message) => {
    return (
      <List.Item style={{ backgroundColor: "#fff" }}>
        <div style={{ width: "100%" }}>
          <Row wrap={false} justify={"space-between"} align={"top"}>
            {/*<Col flex={"none"}>{getCheckBoxPart(message)}</Col>*/}
            <Col flex={"auto"}>{getBodyPart(message)}</Col>
            <Col flex={"none"}>{getRightPart(message)}</Col>
          </Row>
        </div>
      </List.Item>
    );
  };

  const getJsx = () => {
    if (!messages) {
      return <Skeleton active={true} />;
    }

    return (
      <>
        <SmartInboxEducationModal forced={true} />
        <SpaceBetweenRow
          leftSide={
            <SortSelect
              orderByValue={orderBy}
              onChange={(selectedItem) => {
                changeOrderOrFilterAndNavigate("order", selectedItem);
              }}
              selectStyle={{ width: 190 }}
            />
          }
          rightSide={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  if (viewArchived) {
                    navigate(`/families/${familyId}/smart-inbox`);
                  } else {
                    navigate(`/families/${familyId}/archived-messages`);
                  }
                }}
              >
                {viewArchived ? "View Inbox" : "View Archived"}
              </Button>
              {isDebugMode && (
                <SmallButtonWithIcon
                  iconType={"SYNC"}
                  onClick={() => runGmailQueryHook()}
                />
              )}
            </Space>
          }
        />
        <SpaceBetweenRow
          leftSide={<></>}
          rightSide={
            <StandardPagination
              current={currentPage}
              pageSize={pageSize}
              onChange={(page) => {
                changePage(page);
              }}
              total={messages?.found}
            />
          }
        />

        {messages?.items?.length === 0 ? (
          <></>
        ) : (
          <List
            itemLayout="horizontal"
            loading={!messages}
            dataSource={messages.items}
            renderItem={(message) => getMessageListItem(message)}
          />
        )}
        <Divider style={{ marginTop: 0 }} />
        <div style={{ display: "flex", justifyContent: "right" }}>
          <StandardPagination
            current={currentPage}
            pageSize={pageSize}
            onChange={(page) => {
              changePage(page);
            }}
            total={messages?.found}
          />
        </div>
      </>
    );
  };

  return getJsx();
};

export default MessagesAsCards;
