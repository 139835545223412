import React, { useEffect, useState } from "react";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useListOrchestrateObjects from "../../hooks/orchestrate/useListOrchestrateObjects";
import { O_SERVICE_FAMILY_MEMBERS } from "../../serviceUrls/serviceUrlsOrchestrate";

const FamilyMemberName = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(undefined);
  const { data: currentUser, isLoading: isCurrentUserLoading } =
    useGetCurrentUser();
  const { data: familyMembers, isLoading: areFamilyMembersLoading } =
    useListOrchestrateObjects(O_SERVICE_FAMILY_MEMBERS, true);

  useEffect(() => {
    if (!isCurrentUserLoading && !areFamilyMembersLoading) {
      for (const i in familyMembers) {
        const memberId = familyMembers[i].member.id;
        if (memberId === parseInt(userId)) {
          setSelectedUser(familyMembers[i].member);
        }
      }
    }
  }, [
    currentUser,
    isCurrentUserLoading,
    familyMembers,
    areFamilyMembersLoading,
    userId,
  ]);

  return <>{selectedUser ? selectedUser.name : ""}</>;
};

export default FamilyMemberName;
