// EMAIL COLLECTOR - Base Object Endpoints
// =====================================================================================================================
const EC_MESSAGES = "messages";
const EC_WORKING_REQUESTS = "working-requests";
const EC_ARCHIVED_WORKING_REQUESTS = "archived-working-requests";
const EC_ARTIFACTS = "artifacts";
const EC_ARTIFACT_ROLLUPS = "artifact-rollups";
const EC_TEXT_BY_FINGERPRINT = "text-by-fingerprint";
const EC_EVENTS = "events";
const EC_TOPICS = "topics";
const EC_JOINABLE_TASKS = "joinable-tasks";
const EC_INBOUND_FORWARDS = "inbound-forwards";

// EMAIL COLLECTOR - Working Requests
// =====================================================================================================================
export const EC_SERVICE_WORKING_REQUESTS_LIST_FOR_MESSAGE = `/${EC_WORKING_REQUESTS}/${EC_MESSAGES}/{uniqueMessageId}`;
export const EC_SERVICE_ARCHIVED_WORKING_REQUESTS_LIST_FOR_MESSAGE = `/${EC_ARCHIVED_WORKING_REQUESTS}/{uniqueMessageId}`;

// EMAIL COLLECTOR - Artifacts
// =====================================================================================================================
export const EC_SERVICE_MESSAGES_LIST_ARTIFACTS_FOR_MESSAGE = `/${EC_MESSAGES}/{uniqueMessageId}/artifacts`;
export const EC_SERVICE_MESSAGES_LIST_OBJECT_STORE_ARTIFACTS_FOR_MESSAGE = `/${EC_MESSAGES}/{uniqueMessageId}/object-store-artifacts`;
export const EC_SERVICE_ARTIFACT_ROLLUPS_LIST_FOR_TENANT = `/${EC_ARTIFACT_ROLLUPS}`;
export const EC_SERVICE_TEXT_FOR_FINGERPRINT_GET = `/${EC_TEXT_BY_FINGERPRINT}/{fingerprint}`;
export const EC_SERVICE_BATCH_GET_ARTIFACTS = `/batch-get-artifacts`;
export const EC_SERVICE_UNROLL_ANY_ARTIFACT_GET = `/${EC_ARTIFACTS}/{classId}/{hashKey}/{sortKey}/unroll`;
export const EC_SERVICE_ARTIFACT_ROLLUP_UPDATE_HIDDEN = `/${EC_ARTIFACTS}/{fingerprint}/update-hidden`;

// EMAIL COLLECTOR - Messages
// =====================================================================================================================
export const EC_SERVICE_MESSAGES_GET_CONTENT = `/${EC_MESSAGES}/{uniqueMessageId}/content`;
export const EC_SERVICE_MESSAGES_GET = `/${EC_MESSAGES}/{uniqueMessageId}`;
export const EC_SERVICE_MESSAGES_NEXT_IDS_GET = `/${EC_MESSAGES}/{uniqueMessageId}/next`;
export const EC_SERVICE_MESSAGES_PREV_IDS_GET = `/${EC_MESSAGES}/{uniqueMessageId}/prev`;
export const EC_SERVICE_MESSAGES_LIST = `/${EC_MESSAGES}`;
export const EC_SERVICE_MESSAGES_BULK_UPDATE = `/${EC_MESSAGES}/bulk-update`;
export const EC_SERVICE_MESSAGES_FOR_ARTIFACT = `/${EC_MESSAGES}/artifacts/{classId}/{hashKey}/{sortKey}`;
export const EC_SERVICE_MESSAGE_BATCH_GET = `/${EC_MESSAGES}/batch-get`;
export const EC_SERVICE_JOINABLE_TASKS = `/${EC_JOINABLE_TASKS}`;

// EMAIL COLLECTOR - Events
// =====================================================================================================================
export const EC_SERVICE_EVENTS = `/${EC_EVENTS}`;
export const EC_SERVICE_MAGIC_EVENTS_UPDATE_HIDDEN = `/${EC_EVENTS}/{eventRollupSortKey}/update-hidden`;
export const EC_SERVICE_MAGIC_EVENTS_LIST_MESSAGES_FOR_EVENT = `/${EC_EVENTS}/{eventRollupSortKey}/messages`;
export const EC_SERVICE_MAGIC_EVENTS_BATCH_GET = `/${EC_EVENTS}/batch-get`;

// EMAIL COLLECTOR - Topics
// =====================================================================================================================
export const EC_SERVICE_TOPICS = `/${EC_TOPICS}`;

// EMAIL COLLECTOR - Inbound Forwards
// =====================================================================================================================
export const EC_SERVICE_INBOUND_FORWARDS_GET = `/${EC_INBOUND_FORWARDS}/{inboundForwardId}`; // No auth
