import React, { useEffect, useState } from "react";
import FullScreenOverlay from "../common/generic/fullScreenOverlay";
import ConnectGoogleCalendar from "./connectGoogleCalendar";
import useGetGoogleAuthTokenInfo from "./hooks/useGetGoogleAuthTokenInfo";

const ConnectGoogleCalendarOverlay = ({ open, onClose }) => {
  const { data: googleAuthTokenInfoState } = useGetGoogleAuthTokenInfo(
    open === true,
  );
  const [isConnected, setIsConnected] = useState(null);

  useEffect(() => {
    if (googleAuthTokenInfoState) {
      if (
        googleAuthTokenInfoState.scope &&
        googleAuthTokenInfoState.scope.includes(
          "https://www.googleapis.com/auth/calendar",
        )
      ) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    } else {
      setIsConnected(null);
    }
  }, [open, googleAuthTokenInfoState]);

  const getInnerContent = () => {
    if (isConnected === true) {
      return (
        <h3>
          Good News! You've already connected Google Calendar with TLDR Parents.
        </h3>
      );
    } else if (isConnected === false) {
      return (
        <ConnectGoogleCalendar
          connectedText={"Google Calendar Connected"}
          loadingText={"Loading..."}
        />
      );
    } else {
      return <h3>Loading...</h3>;
    }
  };

  return (
    <FullScreenOverlay
      title={"Connect Google Calendar"}
      open={open}
      onClose={onClose}
    >
      {getInnerContent()}
    </FullScreenOverlay>
  );
};

export default ConnectGoogleCalendarOverlay;
