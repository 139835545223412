import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useGetCurrentUser from "../user/useGetCurrentUser";
import useSimpleFamilyDetails from "../user/useSimpleFamilyDetails";
import useUpdateableMessage from "../../utils/useUpdateableMessage";
import useInvalidateQuery from "../../utils/useInvalidateQuery";
import useGetCurrentUserInboxId from "../user/useGetCurrentUserInboxId";
import useGetUserEducationSubjects from "../../../components/userEducation/userEducation/useGetUserEducationSubjects";
import useBreadcrumbStore from "../../../components/layout/hooks/useBreadcrumbStore";
import { WarningOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const useTldrPageContext = () => {
  const { familyId } = useParams();
  const location = useLocation();
  const { data: currentUser, refetch: refetchCurrentUser } =
    useGetCurrentUser();
  const currentFamily = useSimpleFamilyDetails(familyId, currentUser);
  const { data: currentUserEducationSubjects } = useGetUserEducationSubjects();
  const currentUserInboxId = useGetCurrentUserInboxId(familyId, currentUser);
  const setBreadcrumbs = useBreadcrumbStore((state) => state.setBreadcrumbs);
  const clearBreadcrumbs = useBreadcrumbStore(
    (state) => state.clearBreadcrumbs,
  );
  const setBreadcrumbExtra = useBreadcrumbStore(
    (state) => state.setBreadcrumbExtra,
  );
  const clearBreadcrumbExtra = useBreadcrumbStore(
    (state) => state.clearBreadcrumbExtra,
  );
  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useUpdateableMessage();
  const { invalidateQueryWhereKeyIs, invalidateQueryWhereKeyContains } =
    useInvalidateQuery();
  const [isLoading, setIsLoading] = useState(true);

  const [isCurrentUserFamilyOwner, setIsCurrentUserFamilyOwner] =
    useState(false);
  const [
    isSetupForGoogleCalendarIntegration,
    setIsSetupForGoogleCalendarIntegration,
  ] = useState(false);

  useEffect(() => {
    if (currentUser && currentFamily) {
      if (currentFamily.owner_id === currentUser.id) {
        setIsCurrentUserFamilyOwner(true);
      } else {
        setIsCurrentUserFamilyOwner(false);
      }

      const googleIdentity =
        currentUser.identities_by_provider?.["google-oauth2"];
      if (googleIdentity) {
        const scopes = googleIdentity.scopes || [];
        if (
          googleIdentity.is_token_invalid !== true &&
          googleIdentity.is_access_token_expired === false &&
          scopes.includes("https://www.googleapis.com/auth/calendar") &&
          currentFamily.google_calendar_id
        ) {
          setIsSetupForGoogleCalendarIntegration(true);
        }
      }
    }
  }, [currentUser, currentFamily]);

  useEffect(() => {
    if (
      familyId &&
      location &&
      currentUser &&
      currentFamily &&
      currentUserEducationSubjects &&
      openLoadingMessage &&
      openSuccessMessage &&
      openErrorMessage &&
      invalidateQueryWhereKeyIs &&
      invalidateQueryWhereKeyContains
    ) {
      setIsLoading(false);
    }
  }, [
    familyId,
    location,
    currentUser,
    currentFamily,
    currentUserEducationSubjects,
    setBreadcrumbs,
    clearBreadcrumbs,
    setBreadcrumbExtra,
    clearBreadcrumbExtra,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyIs,
    invalidateQueryWhereKeyContains,
  ]);

  const getInboxById = (inboxId) => {
    for (const i in currentFamily?.inboxes || []) {
      const inbox = currentFamily.inboxes[i];
      if (inbox.id === parseInt(inboxId)) {
        return inbox;
      }
    }
    return null;
  };

  const showDeleteConfirm = (title, content, onDelete, okText) => {
    if (!okText) {
      okText = "Delete";
    }
    Modal.confirm({
      title: title,
      icon: <WarningOutlined />,
      content: content,
      okText: okText,
      okType: "danger",
      cancelText: "Cancel",
      onOk: onDelete,
    });
  };

  return {
    familyId,
    location,
    currentUser,
    refetchCurrentUser,
    currentUserInboxId,
    currentFamily,
    currentUserEducationSubjects,
    setBreadcrumbs,
    clearBreadcrumbs,
    setBreadcrumbExtra,
    clearBreadcrumbExtra,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyIs,
    invalidateQueryWhereKeyContains,
    isLoading,
    isCurrentUserFamilyOwner,
    isSetupForGoogleCalendarIntegration,
    getInboxById,
    showDeleteConfirm,
    browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

export default useTldrPageContext;
