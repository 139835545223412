import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../../../hooks/framework/apiClient";
import { BACKEND_API_BASE_URL_COMMENTS } from "../../../serviceUrls/baseUrls";
import { C_COMMENTS_TENANT_ID } from "../../../serviceUrls/serviceUrlsComments";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";

const useCreateComment = (onSubmit, onSuccess, onError) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  return useMutation({
    mutationFn: async (data) => {
      const { tenantId, ...comment } = data;
      const token = await getAccessTokenSilently();
      const endpoint = replacePlaceholdersInTemplate(C_COMMENTS_TENANT_ID, {
        tenantId,
      });
      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_COMMENTS,
        endpoint,
        token,
      ).post(comment);
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export default useCreateComment;
