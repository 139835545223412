import useTldrPageContext from "../orchestrate/common/useTldrPageContext";
import { useState } from "react";
import useCreateTileWithAssociation from "../../components/familyOrganizer/boards/hooks/useCreateTileWithAssociation";
import useCreateComment from "../../components/comments/hooks/useCreateComment";
import {
  O_SERVICE_BOARDS_CREATE,
  O_SERVICE_BOARDS_UPDATE,
  O_SERVICE_BOARDS_UPDATE_COVER_IMAGE,
  O_SERVICE_CLIPPINGS_CREATE,
  O_SERVICE_CLIPPINGS_UPDATE,
  O_SERVICE_GOOGLE_CALENDAR_CREATE_EVENT,
  O_SERVICE_GOOGLE_EVENTS_CREATE,
  O_SERVICE_TILES_UPDATE,
  O_SERVICE_TODOS_CREATE,
  O_SERVICE_TODOS_UPDATE,
} from "../../serviceUrls/serviceUrlsOrchestrate";
import { generateGoogleCalendarTemplateUrl } from "../../components/googleCalendar/googleCalendarEventTemplateHelpers";
import { getUrlToArtifactDetails } from "../../utils/ecUtils";
import useUpdateOrchestrateObjectWithNotifications from "../orchestrate/useUpdateOrchestrateObjectWithNotifications";
import useCreateOrchestrateObjectWithNotifications from "../orchestrate/useCreateOrchestrateObjectWithNotifications";
import { useNavigate } from "react-router-dom";
import useCreateOrchestrateObject from "../orchestrate/useCreateOrchestrateObject";

const useTldrUniversalMutateContextForModals = (
  eventsQueryKey,
  onPersistEvent,
  todosQueryKey,
  onPersistTodo,
  clippingsQueryKey,
  onPersistClipping,
  boardsQueryKey,
  onPersistBoard,
  tilesQueryKey,
  onPersistTile,
  useImageForBoardCoverQueryKey,
  onPersistUseImageForBoardCover,
  commentsQueryKey,
  onPersistComment,
) => {
  const {
    currentUser,
    isSetupForGoogleCalendarIntegration,
    familyId,
    currentFamily,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
  } = useTldrPageContext();

  const navigate = useNavigate();

  // Events
  // ===================================================================================================================
  const [eventConfirmLoading] = useState(false);

  const { mutate: handleCreateGoogleEvent } = useCreateOrchestrateObject(
    O_SERVICE_GOOGLE_EVENTS_CREATE,
    null,
    () => {},
    () => {},
    () => {},
  );

  const { mutate: handleCreateGoogleEventNew } = useCreateOrchestrateObject(
    O_SERVICE_GOOGLE_CALENDAR_CREATE_EVENT,
    null,
    () => {
      openLoadingMessage("create-google-event", "Adding event");
    },
    (response) => {
      openSuccessMessage(
        "create-google-event",
        "Successfully created!",
        <a target="_blank" rel="noreferrer" href={response}>
          Open in Google Calendar
        </a>,
      );
      console.log(response);
    },
    () => {
      openErrorMessage("create-google-event", "Error");
    },
  );

  const handleCreateEvent = (values) => {
    const inviteEmails = (values.invitedUsers || []).map((user) => {
      return user.email;
    });

    const urlToArtifactDetailsPage = getUrlToArtifactDetails(
      currentFamily.id,
      values.rollup_class_id,
      values.rollup_hash_key,
      values.rollup_sort_key,
    );

    const googleCalendarUrl = generateGoogleCalendarTemplateUrl(
      values.title,
      values.location,
      values.datetimes.date1,
      values.datetimes.time1,
      values.datetimes.date2,
      values.datetimes.time2,
      currentFamily.timezone,
      `<p>${values.details}</p><p><a href="${urlToArtifactDetailsPage}">Open in TLDR Parents</a></p>`,
      inviteEmails,
    );

    if (!isSetupForGoogleCalendarIntegration) {
      window.open(googleCalendarUrl, "_blank");
    }

    const valuesForPostToTldr = { ...values };
    valuesForPostToTldr.name = values.title;
    delete valuesForPostToTldr.title;

    valuesForPostToTldr.start_date = values.datetimes?.date1;
    valuesForPostToTldr.start_time = values.datetimes?.time1 || null;
    valuesForPostToTldr.end_date = values.datetimes?.date2 || null;
    valuesForPostToTldr.end_time = values.datetimes?.time2 || null;
    delete valuesForPostToTldr.datetimes;

    valuesForPostToTldr.timezone = currentFamily.timezone;
    valuesForPostToTldr.invite_emails = inviteEmails;

    if (isSetupForGoogleCalendarIntegration) {
      handleCreateGoogleEventNew(valuesForPostToTldr);
    } else {
      handleCreateGoogleEvent(valuesForPostToTldr);
    }
  };

  const handleUpdateEvent = (values) => {
    console.log("TODO");
  };

  // Todos
  // ===================================================================================================================
  const [todoConfirmLoading, setTodoConfirmLoading] = useState(false);

  const { createOrchestrateObject: handleCreateTodo } =
    useCreateOrchestrateObjectWithNotifications(
      O_SERVICE_TODOS_CREATE,
      todosQueryKey,
      "todo",
      "todos",
      "todos",
      "To-do",
      "To-dos",
      (submittedItem) => {
        setTodoConfirmLoading(true);
      },
      (savedItem) => {
        setTodoConfirmLoading(false);
        onPersistTodo(savedItem);
      },
      (error, unsavedItem) => {
        setTodoConfirmLoading(false);
      },
    );

  const { updateOrchestrateObject: handleUpdateTodo } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_TODOS_UPDATE,
      todosQueryKey,
      "todo",
      "todos",
      "todos",
      "To-do",
      "To-dos",
      () => {
        setTodoConfirmLoading(true);
      },
      (savedItem) => {
        setTodoConfirmLoading(false);
        onPersistTodo(savedItem);
      },
      () => {
        setTodoConfirmLoading(false);
      },
    );

  // Clippings
  // ===================================================================================================================
  const [clippingConfirmLoading, setClippingConfirmLoading] = useState(false);

  const { createOrchestrateObject: handleCreateClipping } =
    useCreateOrchestrateObjectWithNotifications(
      O_SERVICE_CLIPPINGS_CREATE,
      clippingsQueryKey,
      "clipping",
      "clippings",
      "clippings",
      "Clipping",
      "Clippings",
      () => {
        setClippingConfirmLoading(true);
      },
      (savedItem) => {
        setClippingConfirmLoading(false);
        onPersistClipping(savedItem);
      },
      () => {
        setClippingConfirmLoading(false);
      },
    );

  const { updateOrchestrateObject: handleUpdateClipping } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_CLIPPINGS_UPDATE,
      clippingsQueryKey,
      "clipping",
      "clippings",
      "clippings",
      "Clipping",
      "Clippings",
      () => {
        setClippingConfirmLoading(true);
      },
      (savedItem) => {
        setClippingConfirmLoading(false);
        onPersistClipping(savedItem);
      },
      () => {
        setClippingConfirmLoading(false);
      },
    );

  // Boards
  // ===================================================================================================================
  const [boardConfirmLoading, setBoardConfirmLoading] = useState(false);

  const { createOrchestrateObject: handleCreateBoard } =
    useCreateOrchestrateObjectWithNotifications(
      O_SERVICE_BOARDS_CREATE,
      boardsQueryKey,
      "board",
      "boards",
      "boards",
      "Board",
      "Boards",
      () => {
        setBoardConfirmLoading(true);
      },
      (savedItem) => {
        setBoardConfirmLoading(false);
        onPersistBoard(savedItem);
      },
      () => {
        setBoardConfirmLoading(false);
      },
    );

  const { updateOrchestrateObject: handleUpdateBoard } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_BOARDS_UPDATE,
      boardsQueryKey,
      "board",
      "boards",
      "boards",
      "Board",
      "Boards",
      () => {
        setBoardConfirmLoading(true);
      },
      (savedItem) => {
        setBoardConfirmLoading(false);
        onPersistBoard(savedItem);
      },
      () => {
        setBoardConfirmLoading(false);
      },
    );

  // Tiles
  // ===================================================================================================================
  const [tileConfirmLoading, setTileConfirmLoading] = useState(false);

  const createTileHook = useCreateTileWithAssociation(
    boardsQueryKey,
    () => {
      setTileConfirmLoading(true);
      openLoadingMessage("create-tile", "Pinning tile to board...");
    },
    (savedItem) => {
      console.log(savedItem);
      setTileConfirmLoading(false);

      openSuccessMessage(
        "create-tile",
        "Pinned tile to board",
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate(`/families/${familyId}/boards/${savedItem.id}`);
          }}
        >
          Go to board
        </a>,
      );
      invalidateQueryWhereKeyContains("boards");
      onPersistTile(savedItem);
    },
    () => {
      setTileConfirmLoading(false);
      openErrorMessage("create-tile", "Error adding tile to board");
      invalidateQueryWhereKeyContains("boards");
    },
  );

  const handleCreateTile = (values) => {
    createTileHook.mutate(values);
  };

  const { updateOrchestrateObject: handleUpdateTile } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_TILES_UPDATE,
      tilesQueryKey,
      "tile",
      "boards",
      "tiles",
      "Tile",
      "Tiles",
      () => {
        setBoardConfirmLoading(true);
      },
      (savedItem) => {
        setBoardConfirmLoading(false);
        onPersistBoard(savedItem);
      },
      () => {
        setBoardConfirmLoading(false);
      },
    );

  // Use Image For Board Cover
  // ===================================================================================================================
  const [
    useImageForBoardCoverConfirmLoading,
    setUseImageForBoardCoverConfirmLoading,
  ] = useState(false);

  const { updateOrchestrateObject: handleUpdateUseImageForBoardCover } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_BOARDS_UPDATE_COVER_IMAGE,
      boardsQueryKey,
      "board",
      "boards",
      "boards",
      "Board",
      "Boards",
      () => {
        setUseImageForBoardCoverConfirmLoading(true);
      },
      (savedItem) => {
        setUseImageForBoardCoverConfirmLoading(false);
        onPersistBoard(savedItem);
      },
      () => {
        setUseImageForBoardCoverConfirmLoading(false);
      },
    );

  // Comments
  // ===================================================================================================================
  const [commentConfirmLoading, setCommentConfirmLoading] = useState(false);

  const createCommentHook = useCreateComment(
    () => {
      setCommentConfirmLoading(true);
      openLoadingMessage("createComment", "Creating comment");
    },
    (savedItem) => {
      setCommentConfirmLoading(false);
      openSuccessMessage("createComment", "Comment created");
      invalidateQueryWhereKeyContains("comments");
      onPersistComment(savedItem);
    },
    () => {
      setCommentConfirmLoading(false);
      openErrorMessage("createComment", "Error creating comment");
      invalidateQueryWhereKeyContains("comments");
    },
  );

  const handleCreateComment = (values) => {
    createCommentHook.mutate(values);
  };

  return {
    eventConfirmLoading,
    handleCreateEvent,
    handleUpdateEvent,
    todoConfirmLoading,
    handleCreateTodo,
    handleUpdateTodo,
    clippingConfirmLoading,
    handleCreateClipping,
    handleUpdateClipping,
    boardConfirmLoading,
    handleCreateBoard,
    handleUpdateBoard,
    tileConfirmLoading,
    handleCreateTile,
    handleUpdateTile,
    useImageForBoardCoverConfirmLoading,
    handleUpdateUseImageForBoardCover,
    commentConfirmLoading,
    handleCreateComment,
  };
};

export default useTldrUniversalMutateContextForModals;
