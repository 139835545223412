import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { O_SERVICE_AUTH_GET_GOOGLE_AUTH_TOKEN_INFO } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";

const useGetGoogleAuthTokenInfo = (enabled) => {
  if (![true, false].includes(enabled)) {
    enabled = true;
  }

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_AUTH_GET_GOOGLE_AUTH_TOKEN_INFO,
    true,
    [],
    {},
    enabled,
  );
};

export default useGetGoogleAuthTokenInfo;
