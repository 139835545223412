// ORCHESTRATE - Base Object Endpoints
// =====================================================================================================================

const O_AUTH = "auth";
const O_BILLING = "billing";
const O_BOARDS = "boards";
const O_TILES = "tiles";
const O_CLIPPINGS = "clippings";
const O_INVITES = "invites";
const O_FAMILIES = "families";
const O_FAMILY_MEMBERS = "family-members";
const O_TODOS = "todos";
const O_GOOGLE_EVENTS = "google-events";
const O_GOOGLE_CALENDAR = "google-calendar";

// ORCHESTRATE - Auth
// =====================================================================================================================
export const O_SERVICE_AUTH_CURRENT_USER_GET = `/${O_AUTH}/current-user`;
export const O_SERVICE_AUTH_UPDATE_TIME_ZONE = `/${O_AUTH}/update-time-zone`;
export const O_SERVICE_AUTH_TRACK_USER_VISIT = `/${O_AUTH}/track-user-visit`;

// ORCHESTRATE - Admin
// =====================================================================================================================
export const O_SERVICE_RUN_ADMIN_JOB = "/admin/run-job";

// ORCHESTRATE - Families
// =====================================================================================================================
export const O_SERVICE_FAMILIES_CREATE = `/${O_FAMILIES}`;
export const O_SERVICE_FAMILIES_UPDATE = `/${O_FAMILIES}/{objectId}`;
export const O_SERVICE_FAMILY_MEMBERS = `/${O_FAMILY_MEMBERS}`;

// ORCHESTRATE - Google
// =====================================================================================================================
export const O_SERVICE_AUTH_GET_GOOGLE_AUTH_TOKEN_INFO = `/${O_AUTH}/get-google-auth-token-info`;
export const O_SERVICE_AUTH_UPDATE_GOOGLE_AUTH_TOKEN_INFO = `/${O_AUTH}/update-google-auth-token-info`;
export const O_SERVICE_AUTH_REVOKE_GOOGLE_REFRESH_TOKEN_INFO = `/${O_AUTH}/revoke-google-refresh-token`;
export const O_SERVICE_RUN_SYNC_GMAIL = "/inbox/run-sync-gmail";

// ORCHESTRATE - Billing
// =====================================================================================================================
export const O_SERVICE_BILLING_DEFAULT_PAYMENT_METHOD = `/${O_BILLING}/default-payment-method`;
export const O_SERVICE_BILLING_GET_INTENT = `/${O_BILLING}/get-intent`;
export const O_SERVICE_BILLING_INVOICES_LIST = `/${O_BILLING}/invoices`;
export const O_SERVICE_BILLING_SUBSCRIPTIONS_MY_FAMILY = `/${O_BILLING}/subscriptions/my-family`;
export const O_SERVICE_BILLING_SUBSCRIPTIONS_UPDATE = `/${O_BILLING}/subscriptions/{objectId}`;

// ORCHESTRATE - Invites
// =====================================================================================================================
export const O_SERVICE_INVITES_LIST = `/${O_INVITES}`;
export const O_SERVICE_INVITES_CREATE = `/${O_INVITES}`;
export const O_SERVICE_INVITES_DELETE = `/${O_INVITES}/{objectId}`;
export const O_SERVICE_INVITES_RESEND_EMAIL = `/${O_INVITES}/{objectId}/resend-email`;
export const O_SERVICE_INVITES_BY_INVITE_CODE_GET = `/${O_INVITES}/{inviteCode}`; // No auth
export const O_SERVICE_INVITES_FOR_CURRENT_USER = `/${O_INVITES}/get-invites-for-current-user`;
export const O_SERVICE_INVITES_ACCEPT_INVITE = `/${O_INVITES}/accept-invite`;
export const O_SERVICE_INVITES_DELETE_BY_RECIPIENT = `/${O_INVITES}/delete-invite-to-current-user/{inviteUuid}`;

// ORCHESTRATE - Boards
// =====================================================================================================================
export const O_SERVICE_BOARDS_LIST = `/${O_BOARDS}`;
export const O_SERVICE_BOARDS_CREATE = `/${O_BOARDS}`;
export const O_SERVICE_BOARDS_GET = `/${O_BOARDS}/{objectId}`;
export const O_SERVICE_BOARDS_UPDATE = `/${O_BOARDS}/{objectId}`;
export const O_SERVICE_BOARDS_DELETE = `/${O_BOARDS}/{objectId}`;
export const O_SERVICE_BOARDS_UPDATE_COVER_IMAGE = `/${O_BOARDS}/{objectId}/cover-image`;
export const O_SERVICE_BOARDS_PUBLIC_BY_UUID_GET = `/${O_BOARDS}/{boardUuid}`; // No auth
export const O_SERVICE_TILES_GET = `/${O_TILES}/{objectId}`;
export const O_SERVICE_TILES_CREATE = `/${O_BOARDS}/{boardId}/${O_TILES}`;
export const O_SERVICE_TILES_UPDATE = `/${O_TILES}/{objectId}`;
export const O_SERVICE_TILES_DELETE = `/${O_TILES}/{objectId}`;

// ORCHESTRATE - Clippings
// =====================================================================================================================
export const O_SERVICE_CLIPPINGS_LIST = `/${O_CLIPPINGS}`;
export const O_SERVICE_CLIPPINGS_CREATE = `/${O_CLIPPINGS}`;
export const O_SERVICE_CLIPPINGS_GET = `/${O_CLIPPINGS}/{objectId}`;
export const O_SERVICE_CLIPPINGS_UPDATE = `/${O_CLIPPINGS}/{objectId}`;
export const O_SERVICE_CLIPPINGS_DELETE = `/${O_CLIPPINGS}/{objectId}`;
export const O_SERVICE_CLIPPINGS_REMOVE_DUPLICATES = `/${O_CLIPPINGS}/remove-duplicates`;

// ORCHESTRATE - Todos
// =====================================================================================================================
export const O_SERVICE_TODOS_LIST = `/${O_TODOS}`;
export const O_SERVICE_TODOS_CREATE = `/${O_TODOS}`;
export const O_SERVICE_TODOS_GET = `/${O_TODOS}/{objectId}`;
export const O_SERVICE_TODOS_UPDATE = `/${O_TODOS}/{objectId}`;
export const O_SERVICE_TODOS_DELETE = `/${O_TODOS}/{objectId}`;

// ORCHESTRATE - Google Events
// =====================================================================================================================
export const O_SERVICE_GOOGLE_EVENTS_LIST = `/${O_GOOGLE_EVENTS}`;
export const O_SERVICE_GOOGLE_EVENTS_CREATE = `/${O_GOOGLE_EVENTS}`;
export const O_SERVICE_GOOGLE_EVENTS_GET = `/${O_GOOGLE_EVENTS}/{objectId}`;
export const O_SERVICE_GOOGLE_EVENTS_DELETE = `/${O_GOOGLE_EVENTS}/{objectId}`;

// ORCHESTRATE - Batch Query
// =====================================================================================================================
export const O_SERVICE_BATCH_QUERY_SAVED_OBJECTS_GIVEN_EC_ARTIFACTS =
  "/batch-query-saved-objects-given-ec-artifacts";

// ORCHESTRATE - Cookie Tracking Info
// =====================================================================================================================
export const O_SERVICE_POST_TRACKING_DATA = `/${O_AUTH}/tracking-info`;

// ORCHESTRATE - Cookie Tracking Info
// =====================================================================================================================
export const O_SERVICE_GOOGLE_CALENDAR_CREATE_EVENT = `/${O_GOOGLE_CALENDAR}/events`;
