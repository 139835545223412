import React, { useEffect } from "react";
import { Alert, Form, Typography } from "antd";
import CreateGoogleEventFormItems from "./createGoogleEventFormItems";

import {
  getCurrentDateString,
  getCurrentTimePlusOneHourStringFlooredToHour,
  getCurrentTimeStringFlooredToHour,
} from "../datetimePickers/dateTimePickerUtils";
import ConnectGoogleCalendar from "../../googleOAuth/connectGoogleCalendar";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const CreateGoogleEventForm = ({
  initialValues,
  ecObject,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const { isSetupForGoogleCalendarIntegration } = useTldrPageContext();
  const [form] = Form.useForm();

  if (!initialValues?.datetimes) {
    const date1 = getCurrentDateString();
    const date2 = getCurrentDateString();
    const time1 = getCurrentTimeStringFlooredToHour();
    const time2 = getCurrentTimePlusOneHourStringFlooredToHour();
    initialValues.datetimes = {
      date1: date1,
      date2: date2,
      time1: time1,
      time2: time2,
    };
  }

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <>
      {isSetupForGoogleCalendarIntegration === false && (
        <Alert
          type={"info"}
          style={{ marginBottom: 20 }}
          showIcon
          message={"No Calendar Connected"}
          description={
            <>
              <div>
                Connect your Google Calendar now to take advantage of TLDR’s
                auto-add functionality and streamline your scheduling process.
              </div>
              <div style={{ margin: "10px 0" }}>
                <ConnectGoogleCalendar
                  connectedText={null}
                  loadingText={null}
                />
              </div>
              <Typography.Text type="secondary">
                TLDR Parents' use and transfer of information received from
                Google APIs to any other app will adhere to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  }
                >
                  Google API Services User Data Policy
                </a>
                , including the Limited Use requirements.
              </Typography.Text>
            </>
          }
        />
      )}

      <Form
        layout="vertical"
        form={form}
        name={"CreateGoogleEventForm"}
        initialValues={initialValues}
        onValuesChange={(value) => {
          if (onValuesChange) {
            onValuesChange?.(value, form.getFieldsValue());
          }
        }}
      >
        <CreateGoogleEventFormItems />
        {isSetupForGoogleCalendarIntegration === false && (
          <Alert
            style={{ marginBottom: 20 }}
            showIcon
            type={"warning"}
            message={"After opening in Google Calendar, remember to hit save."}
          />
        )}
      </Form>
    </>
  );
};

export default CreateGoogleEventForm;
