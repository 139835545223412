import { Actions } from "./actions";

export const AppContextFilesPlusRollupFeed = Object.freeze({
  name: "AppContextFilesPlusRollupFeed",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
      Actions.useImageAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextLinkSuggestions = Object.freeze({
  name: "AppContextLinkSuggestions",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
      Actions.useImageAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextEmailRollupFeed = Object.freeze({
  name: "AppContextEmailRollupFeed",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
      Actions.createContactAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextBoard = Object.freeze({
  name: "AppContextBoard",
  getActions: () => {
    return [
      Actions.createClippingAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createEventAction,
      Actions.createClippingAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.editTileAction,
      Actions.deleteTileBoardAssociationAction,
      Actions.useImageAction,
    ];
  },
  showCopyToWorkbenchButton: false,
  showEditButton: false,
  showDeleteButton: true,
});

export const AppContextObjectDetailOverlay = Object.freeze({
  name: "AppContextObjectDetailOverlay",
  getActions: () => {
    return [
      Actions.createTileWithBoardAssociationAction,
      Actions.createEventAction,
      Actions.createClippingAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.useImageAction,
      Actions.createContactAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextClippingScroll = Object.freeze({
  name: "AppContextClippingScroll",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.deleteClippingAction,
      Actions.useImageAction,
    ];
  },
  showCopyToWorkbenchButton: false,
  showEditButton: false,
  showDeleteButton: true,
});

export const AppContextFilesPlusMessage = Object.freeze({
  name: "AppContextFilesPlusMessage",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
      Actions.createContactAction,
      Actions.useImageAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextSummaryBulletsMessage = Object.freeze({
  name: "AppContextSummaryBulletsMessage",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextPublicBoard = Object.freeze({
  name: "AppContextPublicBoard",
  getActions: () => {
    return [];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextMagicCalendar = Object.freeze({
  name: "AppContextMagicCalendar",
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextViewMessage = Object.freeze({
  name: "AppContextViewMessage",
  getActions: () => {
    return [
      Actions.createEventAction,

      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
    ];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: false,
  showDeleteButton: false,
});

export const AppContextTodoList = Object.freeze({
  name: "AppContextTodoList",
  getActions: () => {
    return [];
  },
  showCopyToWorkbenchButton: true,
  showEditButton: true,
  showDeleteButton: true,
});
