import React from "react";
import ConnectGmail from "../googleOAuth/connectGmail";

import DisconnectAllGoogleButton from "../googleOAuth/disconnectAllGoogleButton";
import ConnectGoogleCalendar from "../googleOAuth/connectGoogleCalendar";

// import ConnectGoogleBasicInfo from "../googleOAuth/connectGoogleBasicInfo";
// import DisplayGoogleConnectionScopes from "../googleOAuth/displayGoogleConnectionScopes";

const GoogleConnections = () => {
  return (
    <div>
      {/*<div style={{ marginBottom: 15 }}>*/}
      {/*  <ConnectGoogleBasicInfo />*/}
      {/*</div>*/}
      <div style={{ marginBottom: 15 }}>
        <ConnectGoogleCalendar
          connectedText={"Google Calendar Connected"}
          loadingText={"Loading..."}
        />
      </div>
      <div style={{ marginBottom: 15 }}>
        <ConnectGmail />
      </div>
      {/*<div style={{ margin: "15px 0" }}>*/}
      {/*  <DisplayGoogleConnectionScopes />*/}
      {/*</div>*/}
      <div style={{ margin: "15px 0" }}>
        <DisconnectAllGoogleButton />
      </div>
    </div>
  );
};

export default GoogleConnections;
