import React from "react";
import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0GoogleScopesProviderContext from "../../context/auth0GoogleScopesProviderContext";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useManageGoogleConnections from "./hooks/useManageGoogleConnections";
import { useLocation } from "react-router-dom";

const AddGoogleScopeButton = ({
  buttonTitle,
  iconSrc,
  iconAlt,
  scopesToAdd,
  connectedText,
  loadingText,
}) => {
  const location = useLocation();
  const { data: currentUser } = useGetCurrentUser();
  const { googleAuthTokenInfoState } = useManageGoogleConnections();
  const { loginWithRedirect } = useAuth0(Auth0GoogleScopesProviderContext);

  const getReturnUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    const nextUrl = searchParams.toString()
      ? `${location.pathname}?${searchParams.toString()}`
      : location.pathname;
    return `/google-add-scopes-callback?next=${encodeURIComponent(nextUrl)}`;
  };

  const getGoogleConnectButton = () => {
    if (!googleAuthTokenInfoState) {
      return <div>{loadingText}</div>;
    }

    if (googleAuthTokenInfoState.scope) {
      if (
        scopesToAdd.every((element) => {
          return googleAuthTokenInfoState.scope.includes(element);
        })
      ) {
        return <div>{connectedText}</div>;
      }
    }

    return (
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#168dff",
          borderColor: "#168dff",
          backgroundColor: "#ffffff",
        }}
        icon={
          <img
            style={{ cursor: "pointer", height: 20, display: "block" }}
            src={iconSrc}
            alt={iconAlt}
          />
        }
        onClick={() => {
          const authParams = {
            authorizationParams: {
              login_hint: currentUser.email,
            },
          };
          const config = { ...authParams };
          config.appState = { returnTo: getReturnUrl() };
          loginWithRedirect(config).then();
        }}
      >
        {buttonTitle}
      </Button>
    );
  };

  return getGoogleConnectButton();
};

export default AddGoogleScopeButton;
