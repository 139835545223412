import { useEffect, useState } from "react";
import useGetGoogleAuthTokenInfo from "./useGetGoogleAuthTokenInfo";
import useUpdateGoogleAuthTokenInfo from "./useUpdateGoogleAuthTokenInfo";
import useRevokeGoogleRefreshToken from "./useRevokeGoogleRefreshToken";

const useManageGoogleConnections = () => {
  const { data: googleAuthTokenInfo } = useGetGoogleAuthTokenInfo();
  const [googleAuthTokenInfoState, setGoogleAuthTokenInfoState] =
    useState(null);

  useEffect(() => {
    if (googleAuthTokenInfo) {
      setGoogleAuthTokenInfoState(googleAuthTokenInfo);
    }
  }, [googleAuthTokenInfo]);

  const { mutate: updateGoogleAuthTokenInfo } = useUpdateGoogleAuthTokenInfo(
    null,
    (response) => {
      setGoogleAuthTokenInfoState(response);
    },
    null,
  );

  const { mutate: revokeGoogleRefreshToken } = useRevokeGoogleRefreshToken(
    null,
    (response) => {
      setGoogleAuthTokenInfoState(response);
    },
    null,
  );

  return {
    googleAuthTokenInfoState,
    updateGoogleAuthTokenInfo,
    revokeGoogleRefreshToken,
  };
};

export default useManageGoogleConnections;
