import { useState } from "react";
import { MERGE_SOURCE_DATA_COMMON } from "../../../components/familyOrganizer/orchestrateObjectPrototypes";
import useTldrUniversalMutateContext from "../../utils/useTldrUniversalMutateContext";
import useGetInitialAndMergeValuesForEdit from "./useGetInitialAndMergeValuesForEdit";

export const EMPTY_CLIPPING = {
  notes: "",
};

export const MERGE_VALUES_CLIPPING = {
  ...MERGE_SOURCE_DATA_COMMON,
};

const useCreateOrEditOrchestrateClipping = () => {
  const { deleteClipping, createClipping } = useTldrUniversalMutateContext();

  const [clippingOverlayOpen, setClippingOverlayOpen] = useState(false);
  const [clippingFormValues, setClippingFormValues] = useState({});
  const [clippingMergeValues, setClippingMergeValues] = useState({});

  const { getInitialValuesForEdit, getMergeValuesForEdit } =
    useGetInitialAndMergeValuesForEdit();

  const handleCreateClipping = (values) => {
    createClipping({
      ...EMPTY_CLIPPING,
      ...MERGE_VALUES_CLIPPING,
      ...values.sourceInfo,
    });
  };

  const handleEditClipping = (values, ecObject) => {
    setClippingFormValues(getInitialValuesForEdit(values.savedObject));
    setClippingMergeValues(getMergeValuesForEdit(values.savedObject));
    openClippingOverlay();
  };

  const handleDeleteClipping = (values, ecObject) => {
    deleteClipping(values.savedObject.id);
  };

  const openClippingOverlay = () => {
    setClippingOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeClippingOverlay = () => {
    setClippingOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    clippingFormValues,
    clippingMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    handleCreateClipping,
    handleDeleteClipping,
    handleEditClipping,
    setClippingFormValues,
    setClippingMergeValues,
  };
};

export default useCreateOrEditOrchestrateClipping;
