import React from "react";
import { useParams } from "react-router-dom";
import SecondaryMeMenu from "./secondaryMeMenu";
import GoogleConnections from "./googleConnections";

const MessageContentOutlet = () => {
  let { viewType } = useParams();
  if (!viewType) viewType = "connections";

  const getContentJsx = () => {
    switch (viewType) {
      case "connections":
        return <GoogleConnections />;

      // case "settings":
      //   return <MyGeneralSettings />;
      // case "invites":
      //   return <MyInvites />;
      // case "admin":
      //   return <MyAdmin />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <SecondaryMeMenu selectedTabValue={viewType} />
      {getContentJsx()}
    </>
  );
};

export default MessageContentOutlet;
