import React from "react";
import OverlayFormWrapper from "../formComponents/overlayFormWrapper";
import CreateGoogleEventForm from "./createGoogleEventForm";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const CreateGoogleEventOverlay = ({
  open,
  onOk,
  onCancel,
  initialValues,
  confirmLoading,
  formMergeValues,
  ecObject,
}) => {
  const { isSetupForGoogleCalendarIntegration } = useTldrPageContext();

  const titleText = "Add to Google Calendar";
  const okText =
    isSetupForGoogleCalendarIntegration === true
      ? "Add"
      : isSetupForGoogleCalendarIntegration === false
        ? "Open in Google Calendar"
        : "";
  const cancelText = "Cancel";

  const handleOk = (values) => {
    const response = { ...values, ...formMergeValues };
    onOk(response);
  };

  return (
    <OverlayFormWrapper
      open={open}
      title={titleText}
      onOk={handleOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      initialValues={initialValues}
      formMergeValues={formMergeValues}
      formClass={CreateGoogleEventForm}
      ecObject={ecObject}
      onValuesChange={() => {}}
      onValidationError={() => {}}
    />
  );
};

export default CreateGoogleEventOverlay;
