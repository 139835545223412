import React from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import useTldrMePageContext from "./hooks/useTldrMePageContext";
import useIsDebugMode from "../../hooks/utils/useIsDebugMode";

const SecondaryMessageMenu = ({ selectedTabValue }) => {
  const navigate = useNavigate();
  const { currentUser } = useTldrMePageContext();
  const { isDebugMode } = useIsDebugMode();

  const tabItems = [
    {
      label: "Connections",
      key: "connections",
    },
    // {
    //   label: "General",
    //   key: "settings",
    // },
    // {
    //   label: "Invites",
    //   key: "invites",
    // },
  ];
  if (currentUser.is_admin && isDebugMode) {
    tabItems.push({
      label: "Admin",
      key: "admin",
    });
  }

  return (
    <Tabs
      activeKey={selectedTabValue || "connections"}
      items={tabItems}
      onChange={(value) => navigate(`/me/${value}`)}
    />
  );
};

export default SecondaryMessageMenu;
