import React, { useEffect } from "react";
import { Form } from "antd";
import BoardSelector2 from "../../../boardSelector2";

const UseImageAsBoardCoverForm = ({
  initialValues,
  ecObject,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name={"UseImageAsBoardCoverForm"}
        initialValues={initialValues}
        onValuesChange={(value) => {
          if (onValuesChange) {
            onValuesChange?.(value, form.getFieldsValue());
          }
        }}
      >
        <Form.Item
          label="Select Board"
          name="board_id"
          validateTrigger={["onChange"]}
          rules={[
            {
              required: true,
              message: "Gotta have a board!",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <BoardSelector2 />
        </Form.Item>
      </Form>
    </>
  );
};

export default UseImageAsBoardCoverForm;
