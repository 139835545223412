import { O_SERVICE_AUTH_REVOKE_GOOGLE_REFRESH_TOKEN_INFO } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import useQueryGenericDelete from "../../../hooks/framework/useQueryGenericDelete";

const useRevokeGoogleRefreshToken = (onSubmit, onSuccess, onError) => {
  return useQueryGenericDelete(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_AUTH_REVOKE_GOOGLE_REFRESH_TOKEN_INFO,
    null,
    onSubmit,
    onSuccess,
    onError,
    true,
  );
};

export default useRevokeGoogleRefreshToken;
