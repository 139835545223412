import Auth0GoogleScopesProviderContext from "../../context/auth0GoogleScopesProviderContext";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";

export const auth0UniversalProviderConfig = {
  context: Auth0UniversalProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
};

const commonGoogleScopesConfig = {
  context: Auth0GoogleScopesProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
};

const commonGoogleScopesAuthParams = {
  scope: "openid profile",
  connection: "google-oauth2",
  access_type: "offline",
  include_granted_scopes: "true",
  prompt: "select_account",
  redirect_uri: `${window.location.origin}`,
};

export const googleBasicInfoProviderConfig = {
  ...commonGoogleScopesConfig,
  authorizationParams: {
    ...commonGoogleScopesAuthParams,
    connection_scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
  },
};

export const gmailProviderConfig = {
  ...commonGoogleScopesConfig,
  authorizationParams: {
    ...commonGoogleScopesAuthParams,
    connection_scope: "https://www.googleapis.com/auth/gmail.readonly",
  },
};

export const googleCalendarProviderConfig = {
  ...commonGoogleScopesConfig,
  authorizationParams: {
    ...commonGoogleScopesAuthParams,
    connection_scope: "https://www.googleapis.com/auth/calendar",
  },
};
