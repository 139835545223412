import React from "react";
import OnboardingPageFrame from "../onboarding/onboardingPageFrame";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import useViewInvitationDetailsFlow from "./hooks/useViewInvitationDetailsFlow";
import { Avatar, Button } from "antd";
import { useLocation } from "react-router-dom";

const ViewInvitationPreAuth = () => {
  const {
    invite,
    loginWithRedirect,
    invalidateQueryWhereKeyContains,
    isAuth0Loading,
    isAuthenticated,
  } = useViewInvitationDetailsFlow();

  const location = useLocation();

  const getJsx = () => {
    if (isAuthenticated || isAuth0Loading || !invite) {
      return <AbsoluteSpin />;
    }

    return (
      <OnboardingPageFrame numSteps={0} currentStep={0} title={""}>
        <div style={{ textAlign: "center" }}>
          <h2>
            {invite.owner.name} is inviting you to join {invite.family.name}.
          </h2>
          <div style={{ paddingTop: 10 }}>
            <Avatar src={invite.owner.picture} size={90} />
          </div>
          <div
            style={{ paddingTop: 15 }}
          >{`${invite.owner.given_name} has invited you to join their family workspace on TLDR Parents.`}</div>
          <div style={{ marginTop: 10 }}>
            <Button
              type={"primary"}
              onClick={async () => {
                const returnTo = location?.pathname;
                const searchParams = location?.search || "";
                const redirectTo = returnTo
                  ? `${returnTo}${searchParams}`
                  : undefined;

                const config = {};
                if (returnTo) {
                  config.appState = { returnTo: redirectTo };
                }
                await loginWithRedirect(config);
                invalidateQueryWhereKeyContains("current-user");
              }}
              size={"large"}
              style={{ width: "100%" }}
            >
              Sign in or sign up
            </Button>
          </div>
        </div>
      </OnboardingPageFrame>
    );
  };

  return getJsx();
};

export default ViewInvitationPreAuth;
