import React from "react";
import FamilyMemberName from "../../../familyMembers/familyMemberName";
import TodoDuePart from "./todoDuePart";
import { Typography } from "antd";

const TodoAssignedToAndDuePart = ({ todoItem }) => {
  const getAssignedToAndDue = () => {
    const response = [];
    if (todoItem.assigned_to_id) {
      response.push(
        <span key="familyMemberName">
          Assigned to{" "}
          <Typography.Text strong={true}>
            <FamilyMemberName userId={todoItem.assigned_to_id} />
          </Typography.Text>
        </span>,
      );
    } else {
      response.push("Unassigned");
    }

    if (todoItem.due) {
      const dueText = response.length ? ", due " : "Due ";
      response.push(
        <span key="dueDate">
          {dueText}
          <Typography.Text strong={true}>
            <TodoDuePart todoItem={todoItem} />
          </Typography.Text>
        </span>,
      );
    }
    return response;
  };

  return getAssignedToAndDue();
};

export default TodoAssignedToAndDuePart;
