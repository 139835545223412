import React from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { getDateForDisplayForISO8601 } from "../../../utils/dayJsUtils";
import FamilyMemberName from "../../familyMembers/familyMemberName";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import FamilyMemberAvatarIcon from "../../familyMembers/familyMemberAvatarIcon";
import useGetOrchestrateObject from "../../../hooks/orchestrate/useGetOrchestrateObject";
import { O_SERVICE_BOARDS_GET } from "../../../serviceUrls/serviceUrlsOrchestrate";
import AnchorNoPropagation from "../../common/generic/anchorNoPropagation";
import { useNavigate } from "react-router-dom";

const TileCard = ({ savedObject, onClickEdit, onClickDelete }) => {
  const { familyId } = useTldrPageContext();
  const navigate = useNavigate();

  const { data: board } = useGetOrchestrateObject(
    O_SERVICE_BOARDS_GET,
    savedObject?.board_associations?.[0]?.board_id,
  );

  return (
    <Row wrap={false} style={{ width: "100%" }}>
      <Col>
        <div style={{ marginTop: 8, marginBottom: 5 }}>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Board: </Typography.Text>
            <AnchorNoPropagation
              onClick={() =>
                navigate(`/families/${familyId}/boards/${board?.id}`)
              }
            >
              {board?.name}
            </AnchorNoPropagation>
          </div>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Added on: </Typography.Text>
            {getDateForDisplayForISO8601(savedObject?.created)}
          </div>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Added by: </Typography.Text>
            <FamilyMemberAvatarIcon
              familyId={familyId}
              userId={savedObject.creator_id}
            />{" "}
            <FamilyMemberName userId={savedObject.creator_id} />
          </div>
          <Space>
            {onClickEdit && (
              <Button
                type={"primary"}
                ghost={true}
                onClick={() => onClickEdit()}
              >
                Edit
              </Button>
            )}
            <Button
              type={"primary"}
              ghost={true}
              danger={true}
              onClick={() => onClickDelete()}
            >
              Delete
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  );
};

export default TileCard;
