import React, { useState } from "react";
import { Button } from "antd";
import ConnectGoogleCalendarOverlay from "./connectGoogleCalendarOverlay";

const Testing2 = () => {
  const [openState, setOpenState] = useState(false);

  return (
    <>
      <Button onClick={() => setOpenState(true)}>Open</Button>
      <ConnectGoogleCalendarOverlay
        open={openState}
        onClose={() => setOpenState(false)}
      />
    </>
  );
};

export default Testing2;
