import React from "react";
import { getFormattedUtcDateTimeInUsersTz } from "../../../../utils/dayJsUtils";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import FamilyMemberName from "../../../familyMembers/familyMemberName";

const TodoCreatedAtAndBySentence = ({ todoItem }) => {
  const { browserTimezone } = useTldrPageContext();

  const getCreatedBy = () => {
    return (
      <>
        Created by <FamilyMemberName userId={todoItem.creator_id} />
        {" on "}
        {getFormattedUtcDateTimeInUsersTz(todoItem.created, browserTimezone)}
      </>
    );
  };

  return getCreatedBy();
};

export default TodoCreatedAtAndBySentence;
