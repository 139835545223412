import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import SecondaryMessageMenu from "./secondaryMessageMenu";
import MessageFullTextView from "./views/messageFullTextView";
import MessageDebugView from "./debug/messageDebugView";
import MessageFilesPlusView from "./views/messageFilesPlusView";
import { Skeleton } from "antd";
import MessageSummaryView from "./views/messageSummaryView";

const MessageContentOutlet = () => {
  const {
    messageObject: [messageObject],
  } = useOutletContext();
  const { familyId, uniqueMessageId } = useParams();
  let { viewType } = useParams();
  if (!viewType) viewType = "tldr";

  const getContentJsx = () => {
    switch (viewType) {
      case "tldr":
        return <MessageSummaryView />;
      case "full-text":
        return <MessageFullTextView />;
      case "more":
        return <MessageFilesPlusView />;
      case "debug":
        return <MessageDebugView />;
      default:
        return <MessageSummaryView />;
    }
  };

  const getSecondaryNavMenu = () => {
    if (!messageObject) {
      return "Loading...";
    }

    return (
      <SecondaryMessageMenu
        familyId={familyId}
        uniqueMessageId={uniqueMessageId}
        selectedTabValue={viewType}
      />
    );
  };

  const getContent = () => {
    if (!messageObject) {
      return <Skeleton active={true} />;
    }

    return getContentJsx();
  };

  return (
    <>
      {getSecondaryNavMenu()}
      {getContent()}
    </>
  );
};

export default MessageContentOutlet;
