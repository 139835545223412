import React, { useState } from "react";
import { Layout, Space, Typography } from "antd";
import ButtonWithCustomColor from "../generic/buttonWithCustomColor";

const { Header, Content } = Layout;

const OverlayFormWrapper = ({
  open,
  title,
  onOk,
  okText,
  onCancel,
  cancelText,
  confirmLoading,
  initialValues,
  formClass,
  ecObject,
  onValuesChange,
  onValidationError,
}) => {
  const [formInstance, setFormInstance] = useState();
  const FormClass = formClass;

  const getForm = () => {
    return (
      <FormClass
        initialValues={initialValues}
        ecObject={ecObject}
        onValuesChange={(value, values) => {
          if (onValuesChange) {
            onValuesChange(value, values);
          }
        }}
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
      />
    );
  };

  const getLeft = () => {
    return (
      <Typography.Title ellipsis={true} level={2}>
        {title}
      </Typography.Title>
    );
  };

  const getButtons = () => {
    return (
      <Space>
        <ButtonWithCustomColor
          primaryColor={"#ff4081"}
          type={"primary"}
          ghost={true}
          disabled={confirmLoading}
          onClick={() => {
            onCancel();
          }}
        >
          {cancelText || "Cancel"}
        </ButtonWithCustomColor>
        <ButtonWithCustomColor
          primaryColor={"#ff4081"}
          loading={confirmLoading}
          type={"primary"}
          onClick={async () => {
            try {
              const values = await formInstance?.validateFields();
              formInstance?.resetFields();
              onOk({ ...values });
            } catch (error) {
              onValidationError(error);
            }
          }}
        >
          {okText || "OK"}
        </ButtonWithCustomColor>
      </Space>
    );
  };

  const getContent = () => {
    return getForm();
  };

  const getJsx = () => {
    if (open) {
      return (
        <Layout
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: 998,
            left: 0,
            top: 0,
            backgroundColor: "rgba(255, 255, 255)",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 998,
              padding: 0,
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderBottom: "2px solid #f0f0f0",
              backdropFilter: "blur(1px)",
              height: "auto",
              marginBottom: 20,
              lineHeight: 1.5,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                height: 75,
              }}
            >
              <div
                style={{
                  maxWidth: 1400,
                  minWidth: 300,
                  width: "100%",
                  paddingTop: 22,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: 35,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexShrink: 1,
                      minWidth: 0,
                      maxWidth: "100%",
                    }}
                  >
                    {getLeft()}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </Header>
          <Layout
            style={{
              backgroundColor: "rgba(255, 255, 255)",
            }}
          >
            <Content>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div
                  style={{
                    maxWidth: 1400,
                    minWidth: 300,
                    width: "100%",
                    padding: 10,
                  }}
                >
                  {getContent()}
                  {getButtons()}
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      );
    }
  };

  return getJsx();
};

export default OverlayFormWrapper;
